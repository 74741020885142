const __pages_import_0__ = () => import("/src/pages/words/index.vue");
const __pages_import_1__ = () => import("/src/pages/defs/index.vue");
const __pages_import_2__ = () => import("/src/pages/authors/index.vue");
const __pages_import_3__ = () => import("/src/pages/author/index.vue");
const __pages_import_4__ = () => import("/src/pages/index.vue");
const __pages_import_5__ = () => import("/src/pages/[...all].vue");
const __pages_import_6__ = () => import("/src/pages/authors/[uid].vue");
const __pages_import_7__ = () => import("/src/pages/defs/[uid].vue");
const __pages_import_8__ = () => import("/src/pages/words/[uid].vue");

const routes = [{"name":"words","path":"/words","component":__pages_import_0__,"props":true},{"name":"defs","path":"/defs","component":__pages_import_1__,"props":true},{"name":"authors","path":"/authors","component":__pages_import_2__,"props":true},{"name":"author","path":"/author","component":__pages_import_3__,"props":true},{"name":"index","path":"/","component":__pages_import_4__,"props":true},{"name":"all","path":"/:all(.*)*","component":__pages_import_5__,"props":true},{"name":"authors-uid","path":"/authors/:uid","component":__pages_import_6__,"props":true},{"name":"defs-uid","path":"/defs/:uid","component":__pages_import_7__,"props":true},{"name":"words-uid","path":"/words/:uid","component":__pages_import_8__,"props":true}];

export default routes;